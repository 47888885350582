import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import coverPhoto from '../assets/images/banner-12.jpg'

class ServicesSupervision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Supervised Visitation - Carien Zeeman</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>Supervised Visitation</h2>
              </header>
              <span className="image main">
                <img src={coverPhoto} alt="" />
              </span>
              <p>Supervised visitation is when the non-custodial parent visits the child(ren) while being supervised by another adult. Supervised visitation allows parents in high risk situations access to their children whilst maintaining the child’s safety. The purpose of such visits therefore provides a safe and neutral environment in which the parent-child relationship is supported and sustained.</p>
              <p>Carien is available to act as a supervisory third person during scheduled visitations between parent and child(ren).</p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesSupervision
